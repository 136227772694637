<template>
	<DefaultLayout>
		<div class="p-4">
			<RegisterForm :is-modal="false" />
		</div>
	</DefaultLayout>
</template>

<script async>
import RegisterForm from '@/components/multiUse/RegisterForm.vue'
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		DefaultLayout,
		RegisterForm
	},
	data() {
		return {
			name: '',
			company: '',
			phone: '',
			email: '',
			department: '',
			message: '',
			meta: {
				title: 'Join today and find CBD oil for sale at local CBD shops and online CBD retailers near you!',
				description: 'Join today and find CBD oil, cbd oil for sale, cbd tinctures for sale, cbd gummies for sale, cbd vape juice for sale, cbd vaporizers',
				keywords: 'CBD oil, cbd oil for sale, cbd tinctures for sale, cbd gummies for sale, cbd vape juice for sale, cbd vaporizers',
				canonical: '/join'
			}
		}
	},
	created() {
		this.$store.commit('setMeta', this.meta)
	}
}
</script>
