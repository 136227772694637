<template>
	<DefaultLayout>
		<div class="flex flex-col w-full max-w-sm p-8 mx-auto">
			<form
				class="w-full mb-4"
				@submit.prevent="handleSubmit"
			>
				<h1 class="pb-3 text-3xl font-bold">
					{{ headingText }}
				</h1>

				<p class="pb-4 text-gray-500">
					{{ formCopy }}
				</p>

				<WwTextInput
					id="forgot-email"
					v-model="formData.email"
					:input-type="EMAIL"
					placeholder="Email"
					:value="formData.email"
					input-class="w-full my-2"
					required
					:state="inputFieldState(formData.email, EMAIL)"
					autocomplete="email"
				/>

				<WwTextInput
					v-if="isPasswordResetPage"
					id="forgot-username"
					v-model="formData.username"
					placeholder="Username"
					:value="formData.username"
					input-class="w-full my-2"
					required
					:state="inputFieldState(formData.username, TEXT)"
					autocomplete="username"
				/>

				<button
					type="submit"
					:disabled="isDisabled"
					:class="isDisabled ? 'bg-green-300 cursor-not-allowed' : 'bg-green-500'"
					class="my-1 w-full p-2.5 text-white rounded font-bold"
				>
					Send Instructions
				</button>
			</form>
			<button
				class="font-bold button-no-styles primary text-primary hover:text-green-500"
				@click="toggleForm"
			>
				{{ formToggleButtonText }}
			</button>
		</div>
	</DefaultLayout>
</template>

<script async>
import { mapActions, mapMutations } from 'vuex'

import WwTextInput from '@/components/UI/WwTextInput.vue'
import INPUT_STATES from '@/constants/text-inputs/states.js'
import { EMAIL, TEXT } from '@/constants/text-inputs/types.js'
import { SUCCESS } from '@/constants/toast/type.js'
import DefaultLayout from '@/layouts/default/Index.vue'
import { logError } from '@/utils/error-handling.js'
import { inputFieldState } from '@/validators/text-inputs.js'

const RESET_FORM_TYPES = {
	USERNAME: 'username',
	PASSWORD: 'password'
}

export default {
	components: {
		DefaultLayout,
		WwTextInput
	},
	data() {
		return {
			RESET_FORM_TYPES,
			formType: RESET_FORM_TYPES.PASSWORD,
			formData: {
				email: '',
				username: ''
			},
			EMAIL,
			TEXT,
			inputFieldState
		}
	},
	computed: {
		isPasswordResetPage() {
			return this.formType === RESET_FORM_TYPES.PASSWORD
		},
		isDisabled() {
			if (this.isPasswordResetPage) {
				return !this.formData.username.length || inputFieldState(this.formData.email, EMAIL) === INPUT_STATES.ERROR
			} else {
				return inputFieldState(this.formData.email, EMAIL) === INPUT_STATES.ERROR
			}
		},
		formCopy() {
			return `Enter your email address and we'll send you instructions to reset your ${this.formType}.`
		},
		headingText() {
			return `Reset ${this.formType}`
		},
		formToggleButtonText() {
			return this.formType === RESET_FORM_TYPES.USERNAME
				? 'Forgot your password?'
				: 'Forgot your username?'
		}
	},
	methods: {
		...mapMutations('toast', [ 'showToast' ]),
		toggleForm() {
			switch (this.formType) {
				case RESET_FORM_TYPES.USERNAME:
					this.formType = RESET_FORM_TYPES.PASSWORD
					break
				case RESET_FORM_TYPES.PASSWORD:
				default:
					this.formType = RESET_FORM_TYPES.USERNAME
			}
		},
		handleSubmit() {
			this.formType === RESET_FORM_TYPES.USERNAME
				? this.handleResetUsername()
				: this.handleResetPassword()
		},
		async handleResetPassword() {
			try {
				const endpoint = 'User/forgotPassword'
				const params = this.formData
				const response = await this.newPost({
					endpoint, params, store: this.$store
				})
				if (response.data.success) {
					this.showToast({
						primaryText: 'Password Reset - Check your email for instructions',
						type: SUCCESS
					})
				}
			} catch (error) {
				logError(error)
			}
		},
		handleResetUsername: async function() {
			try {
				const endpoint = 'User/forgotUsername'
				const params = this.formData
				const response = await this.newPost({
					endpoint, params, store: this.$store
				})
				if (response.data.success) {
					this.showToast({
						primaryText: 'Username Reset - Check your email for instructions',
						type: SUCCESS
					})
				}
			} catch (error) {
				logError(error)
			}
		},
		fieldState(value) {
			return value?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
		},
		...mapActions([ 'newPost' ])
	}
}
</script>
