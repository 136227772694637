<template>
	<DefaultLayout>
		<div class="p-4">
			<LoginForm
				:route-from="routeFrom"
				:is-modal="false"
			/>
		</div>
	</DefaultLayout>
</template>

<script async>
import LoginForm from '@/components/multiUse/LoginForm.vue'
import DefaultLayout from '@/layouts/default/Index.vue'

export default {
	components: {
		LoginForm,
		DefaultLayout
	},
	props: {
		routeFrom: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			name: '',
			company: '',
			phone: '',
			email: '',
			department: '',
			message: '',
			meta: {
				title: 'Login today and find CBD oil for sale at local CBD shops and online CBD retailers near you!',
				description: 'Login today and find CBD CBD oil, cbd oil for sale, cbd tinctures for sale, cbd gummies for sale, cbd vape juice for sale, cbd vaporizers',
				keywords: 'CBD oil, cbd oil for sale, cbd tinctures for sale, cbd gummies for sale, cbd vape juice for sale, cbd vaporizers',
				canonical: '/login'
			}
		}
	},
	created: function() {
		this.$store.commit('setMeta', this.meta)
	}
}
</script>
